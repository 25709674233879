<template>
  <div class="content">
    <ApplicationNannyInputEducationTypeStudent
      v-model:education-type="applicationNanny.education.types"
    />

    <ButtonPrimaryNext
      :to="{ name: 'ApplicationNannyCreateEducationCourses' }"
    />
  </div>
</template>

<script>
import { useApplicationNannyStore } from "@/stores/applicationNanny";

import ApplicationNannyInputEducationTypeStudent from "@/components/Application/Nanny/ApplicationNannyInputEducationTypeStudent.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";

export default {
  components: {
    ApplicationNannyInputEducationTypeStudent,
    ButtonPrimaryNext,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();

    return {
      applicationNanny,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}

@media (max-width: 1200px) {
}
</style>
